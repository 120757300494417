import axios from "axios";
import { Consts } from "../Constants/Consts";

const getProductType = async (authToken) => {
    try {
        const { data } = await axios.get(`${Consts.BACKEND_URL}products/getProductTypes.php`, {
            headers: {
                'Authorization': authToken
            },
            params: {
                action: 'getCartItems'
            }
        });
        return data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.error : 'Network Error');
    }
};

export default getProductType;
