import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material'
import styles from './Chekout.module.css'
import { BsFillCartCheckFill } from 'react-icons/bs'
import { MdUpdate } from 'react-icons/md'
import axios from 'axios'
import { ContextFunction } from '../../Context/Context'
import { Link, useNavigate } from 'react-router-dom'
import { profile } from '../../Assets/Images/Image'
import { toast } from 'react-toastify'
import CopyRight from '../CopyRight/CopyRight'
import { Transition, handleClose } from '../../Constants/Constant'
import { AiFillCloseCircle, AiOutlineSave } from 'react-icons/ai'
import { Consts } from '../../Constants/Consts'

const CheckoutForm = () => {
    const { cart } = useContext(ContextFunction)
    const [userData, setUserData] = useState([])
    const [openAlert, setOpenAlert] = useState(false);

    let authToken = localStorage.getItem('Authorization')
    let setProceed = authToken ? true : false
    let navigate = useNavigate()
    let totalAmount = sessionStorage.getItem('totalAmount')

    useEffect(() => {
        if (setProceed) {
            getUserData()

        }
        else {
            navigate('/')
        }

    }, [])

    const [userDetails, setUserDetails] = useState({
        first_name: '',
        last_name: '',
        mobile: '',
        email: '',
        address_value: '',
        pincode: '',
        city: '',
        state: '',

    })
    const getUserData = async () => {
        try {
            const { data } = await axios.get(`${Consts.BACKEND_URL}Users/get_user_by_id.php`, {
                headers: {
                    'Authorization': authToken
                }
            })
            setUserData(data?.userDetails);
            var dbData = data?.userDetails;
            if (!dbData.address_value || !dbData.city || !dbData.pincode || !dbData.state) {
                setOpenAlert(true);
                console.log(1);
              }
             
              userDetails.first_name = dbData?.first_name
              userDetails.last_name = dbData?.last_name
              userDetails.email = dbData?.email
              userDetails.mobile = dbData?.mobile
              userDetails.address_value = dbData?.address_value
              userDetails.pincode = dbData?.pincode
              userDetails.city = dbData?.city
              userDetails.state = dbData?.state

        } catch (error) {
            console.log(error);
        }

    }

    const checkOutHandler = async (e) => {
        e.preventDefault();
    
        if (!userDetails.first_name || !userDetails.last_name || !userDetails.email || !userDetails.mobile || !userDetails.address_value || !userDetails.pincode || !userDetails.city || !userDetails.state) {
            toast.error("Please fill all fields", { autoClose: 500, theme: "colored" });
            return;
        }
    
        try {
            const { data } = await axios.post(`${Consts.BACKEND_URL}payment/checkout.php`, {
                amount: totalAmount,
                productDetails: JSON.stringify(cart),
                userDetails: JSON.stringify(userDetails),
            }, {
                headers: {
                    'Authorization': authToken
                }
            });
       
    
            if (!data?.razorpay_options) {
                toast.error("Error initiating payment. Please try again.", { autoClose: 2000, theme: "colored" });
                return;
            }
    
            const razor = new window.Razorpay(data?.razorpay_options);
            razor.on('payment.failed', function (response) {
                toast.error(response.error.description, { autoClose: 2000, theme: "colored" });
            });
    
            razor.open();
    
        } catch (error) {
            console.log("Error:", error.response ? error.response.data : error);
            toast.error("Error initiating payment. Please try again.", { autoClose: 2000, theme: "colored" });
        }
    }
    

    const handleOnchange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
    }



    return (
        <>
            <Container sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: 10 }}>
                <Typography variant='h6' sx={{ margin: '20px 0' }}>Checkout</Typography>
                <form noValidate autoComplete="off" className={styles.checkout_form} onSubmit={checkOutHandler} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField inputProps={{ readOnly: true }} disabled label="First Name" name='first_name' value={userDetails.first_name || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField inputProps={{ readOnly: true }} disabled label="Last Name" name='last_name' value={userDetails.last_name || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField inputProps={{ readOnly: true }} disabled label="Contact Number" type='tel' name='mobile' value={userDetails.mobile || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField inputProps={{ readOnly: true }} disabled label="Email" name='email' value={userDetails.email || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Address" name='address_value' value={userDetails.address_value || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="City" name='city' value={userDetails.city || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type='tel' label="Postal/Zip Code" name='pincode' value={userDetails.pincode || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField label="Province/State" name='state' value={userDetails.state || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                    </Grid>
                    <Container sx={{ display: 'flex', gap: 10, justifyContent: 'center', marginTop: 5 }}>
                        <Link to='/update'> <Button variant='contained' endIcon={<MdUpdate />}>Update</Button></Link>
                        <Button variant='contained' endIcon={<BsFillCartCheckFill />} type='submit'>Checkout</Button>
                    </Container>
                </form >

                <Dialog
                    open={openAlert}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => handleClose(setOpenAlert)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                        <Typography variant='h6'>Add permanent address then you don't have to add again.  </Typography>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Link to='/update'> <Button variant='contained' endIcon={<AiOutlineSave />} color='primary' >Add</Button></Link>
                        <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={() => handleClose(setOpenAlert)}>Close</Button>
                    </DialogActions>
                </Dialog>

            </Container >
            <CopyRight sx={{ mt: 8, mb: 10 }} />

        </>
    )
}

export default CheckoutForm