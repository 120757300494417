import React from 'react'
import styles from './Category.module.css'
import { Link } from 'react-router-dom'

const CategoryCard = ({ data }) => {
    return (
        data && <Link to={`product/type/${data.type_name.toLowerCase()}`}>
            <div className={styles.mainCard}>
                <img src={data.image} alt={data.type_name} className={styles.mainImg} loading='lazy' />
                <div className={styles.text_box}>
                <span className={styles.imgTitle}>{data.type_name}</span>
                </div>
               
            </div>
        </Link>
    )
}

export default CategoryCard