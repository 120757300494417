import React from 'react';

const NotificationSender = () => {
  const handleSendNotification = () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification('Notification Title', {
          body: 'Notification Body',
          // You can add more options here, such as icon, image, etc.
        });
      }).catch((error) => {
        console.error('Error displaying notification:', error);
      });
    } else {
      console.log('This browser does not support service workers or push notifications');
    }
  };

  return (
    <div>
      <button onClick={handleSendNotification}>Send Notification</button>
    </div>
  );
};

export default NotificationSender;
