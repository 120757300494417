import "./Desktop.css";
import React, { useContext, useEffect, useState } from "react";
import {
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiFillCloseCircle,
} from "react-icons/ai";
import { CgProfile, CgUser } from "react-icons/cg";
import { FiLogOut } from "react-icons/fi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Menu,
  MenuItem,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import { ContextFunction } from "../Context/Context";
import { toast } from "react-toastify";
import {
  getCart,
  getWishList,
  handleLogOut,
  handleClickOpen,
  handleClose,
  Transition,
} from "../Constants/Constant";
import { Consts } from "../Constants/Consts";
import axios from "axios";
import UserDrawer from "../Components/UserDrawer/UserDrawer";

const DesktopNavigation = ({ userType, setUserType }) => {
  const { cart, setCart, wishlistData, setWishlistData } =
    useContext(ContextFunction);
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate();
  let authToken = localStorage.getItem("Authorization");
  let setProceed = authToken !== null ? true : false;

  useEffect(() => {
    getCart(setProceed, setCart, authToken);
    getWishList(setProceed, setWishlistData, authToken);
  }, []);

  return (
    <>
      <nav className="nav">
        <div className="logo">
          <Link to="/">
            <span>Capital Looks</span>
          </Link>
        </div>

        {window?.innerWidth < 900 &&
        <div className="nav-links sm_head_icons">
            <NavLink
              to="/cart"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="links">
                <Badge badgeContent={setProceed ? cart.length : 0}>
                  <AiOutlineShoppingCart style={{ fontSize: 23 }} />
                </Badge>
              </div>
            </NavLink>
            <NavLink
              to="/wishlist"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="links">
                <Badge badgeContent={setProceed ? wishlistData.length : 0}>
                  <AiOutlineHeart style={{ fontSize: 23 }} />
                </Badge>
              </div>
            </NavLink>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="links">
                <Badge>
                  <UserDrawer
                    style={{
                      fontSize: 23,
                    }}
                  />
                </Badge>
              </div>
            </div>
        </div>
}
        {/* {userType === Consts.USER_TYPE.ADMIN && (
          <div className="nav-links">
            <Tooltip title="Admin Profile">
              <NavLink to="/admin/home">
                <span className="nav-icon-span">
                  {" "}
                  Admin{" "}
                  <Badge>
                    <CgUser className="nav-icon" />
                  </Badge>
                </span>
              </NavLink>
            </Tooltip>
          </div>
        )} */}
 {window?.innerWidth >= 900 &&
        <div className="nav-items">
          <ul className="nav-items">
            <li className="nav-links">
              <NavLink to="/">
                <span className="nav-icon-span"> Home</span>
              </NavLink>
            </li>
            {/* <li className="nav-links">
              <NavLink to='/contact'>
                <span className='nav-icon-span'>  Contact Us</span>
              </NavLink>
            </li> */}

            <li className="nav-links">
              <Tooltip title="Cart">
                <NavLink to="/cart">
                  <span className="nav-icon-span">
                    Cart{" "}
                    <Badge badgeContent={setProceed ? cart.length : 0}>
                      {" "}
                      <AiOutlineShoppingCart className="nav-icon" />
                    </Badge>
                  </span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-links">
              <Tooltip title="Wishlist">
                <NavLink to="/wishlist">
                  <span className="nav-icon-span">
                    Wishlist{" "}
                    <Badge badgeContent={setProceed ? wishlistData.length : 0}>
                      {" "}
                      <AiOutlineHeart className="nav-icon" />
                    </Badge>
                  </span>
                </NavLink>
              </Tooltip>
            </li>

            {setProceed ? (
              <>
                <li className="nav-links">
                  <Tooltip title="Profile">
                    <span className="nav-icon-span">
                      <UserDrawer
                        style={{
                          fontSize: 29,
                          marginTop: 7,
                          marginRight: 10,
                        }}
                      />
                    </span>
                  </Tooltip>
                </li>

                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                  onClick={() => handleClickOpen(setOpenAlert)}
                >
                  <Button
                    variant="contained"
                    className="nav-icon-span"
                    sx={{ marginBottom: 1 }}
                    endIcon={<FiLogOut />}
                  >
                    <Typography variant="button"> Logout</Typography>
                  </Button>
                </li>
              </>
            ) : (
              <li className="nav-links">
                <Tooltip title="Login">
                  <NavLink to="/login">
                    <span className="nav-icon-span">
                      {" "}
                      <CgProfile style={{ fontSize: 29, marginTop: 7 }} />
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
            )}
          </ul>
        </div>
}
      </nav>
      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          sx={{
            width: { xs: 280, md: 350, xl: 400 },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6"> Do You Want To Logout?</Typography>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Link to="/">
            <Button
              variant="contained"
              endIcon={<FiLogOut />}
              color="primary"
              onClick={() =>
                handleLogOut(
                  setProceed,
                  toast,
                  navigate,
                  setOpenAlert,
                  setUserType
                )
              }
            >
              Logout
            </Button>
          </Link>
          <Button
            variant="contained"
            color="error"
            endIcon={<AiFillCloseCircle />}
            onClick={() => handleClose(setOpenAlert)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DesktopNavigation;
