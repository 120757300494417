import React, { useState } from "react";
import { Tab, Tabs, Typography, Box, useMediaQuery } from "@mui/material";
import { VscGraph } from "react-icons/vsc";
import PropTypes from "prop-types";
import { CgProfile } from "react-icons/cg";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { MdOutlineWifiProtectedSetup } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import ProductTypeTable from "../Tables/ProductTypeTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children} </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminAdditionallTabs = () => {
  const [value, setValue] = useState(0);
  let authToken = localStorage.getItem("Authorization");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box>
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 3,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        style={{ overflowX: "a" }}
      >
        <Tab
          label={!isSmallScreen && "Product Type"}
          {...a11yProps(0)}
          iconPosition="start"
          icon={<VscGraph fontSize={20} />}
        />
        <Tab
          label={!isSmallScreen && "Users"}
          {...a11yProps(1)}
          iconPosition="start"
          icon={<CgProfile fontSize={20} />}
        />
        <Tab
          label={!isSmallScreen && "Products"}
          {...a11yProps(2)}
          iconPosition="start"
          icon={<AiOutlineShoppingCart fontSize={20} />}
        />
        <Tab
          label={!isSmallScreen && "Orders"}
          {...a11yProps(3)}
          iconPosition="start"
          icon={<FaShippingFast fontSize={20} />}
        />
        <Tab
          label={!isSmallScreen && "Additional"}
          {...a11yProps(3)}
          iconPosition="start"
          icon={<MdOutlineWifiProtectedSetup fontSize={20} />}
        />
      </Tabs>
    </Box>
      <TabPanel value={value} index={0}>
      <ProductTypeTable authToken={authToken} />
  </TabPanel>
      </Box >
  );
};

export default AdminAdditionallTabs;
