import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { Consts } from "../../../Constants/Consts";
import AddProductType from "../AddProductType";

const ProductTypeTable = ({ authToken }) => {
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [getTypeInfo, setGetTypeInfo] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const columns = [
    {
      id: "s_no",
      label: "S. No",
      minWidth: 20,
      align: "center",
    },
    {
      id: "id",
      label: "Id",
      minWidth: 100,
      align: "center",
    },
    {
      id: "type_name",
      label: "Type Name",
      minWidth: 170,
      align: "center",
    },
    {
      id: "image",
      label: "Image",
      minWidth: 100,
      align: "center",
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 100,
      align: "center",
    },
  ];

  const filterData = () => {
    if (searchTerm === "") {
      return data;
    }
    return data.filter(
      (item) =>
        (item.type_name &&
          item.type_name?.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.id && item.id.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const newFilteredData = filterData();
    setFilteredData(newFilteredData);
  };

  useEffect(() => {
    setFilteredData(filterData());
  }, [data, searchTerm]);

  useEffect(() => {
    getProductType();
  }, []);

  const getProductType = async () => {
    try {
      const { data } = await axios.get(
        `${Consts.BACKEND_URL}products/getProductTypes.php`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      setData(data?.productTypes);
    } catch (error) {
      toast.error(error.response.data, { autoClose: 500, theme: "colored" });
    }
  };

  const handleEdit = (prod) => {
    setGetTypeInfo(prod);
    setIsEditing(true);
  };


  const handleAdd = () => {
    setIsEditing(false);
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search Products"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ width: { xs: 350, sm: 500, md: 800 } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
        />
      </Container>
      {isEditing ? (
        <AddProductType getTypeInfo={getTypeInfo} setIsopen={setIsEditing} handleAdd={handleAdd} />
      ) : (
        <Button variant="contained" color="primary" onClick={handleAdd}>
          Add New  Type
        </Button>
      )}
      <Paper
        style={{
          overflow: "auto",
          maxHeight: "500px",
        }}
      >
        <TableContainer sx={{ maxHeight: "500px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ position: "sticky", top: 0 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      color: "#1976d2",
                      fontWeight: "bold",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4> Product  Type not found.</h4>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                filteredData?.map((prod, index) => (
                  <TableRow key={prod?._id}>
                    <TableCell align="center">{index}</TableCell>
                    <TableCell align="center">{prod?.id}</TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {prod?.type_name?.slice(0, 30)}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={prod?.image}
                        alt={prod?.type_name}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Button variant="outlined" color="primary" onClick={() => handleEdit(prod)}>
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ProductTypeTable;
