import React, { useEffect, useState } from 'react';
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Transition } from '../../Constants/Constant';
import { MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
import { Consts } from '../../Constants/Consts';

const AddProductType = ({ getTypeInfo,setIsopen }) => {
    let authToken = localStorage.getItem("Authorization");

    const [productInfo, setProductInfo] = useState({
        type_name: "",
        image: "",
    });

    useEffect(() => {
        if (Object.keys(getTypeInfo).length !== 0) {
            setProductInfo({
                type_name: getTypeInfo.type_name,
                image: getTypeInfo.image,
            });
        }
    }, [getTypeInfo]);

    const handleOnchange = (e) => {
        setProductInfo({ ...productInfo, [e.target.name]: e.target.value });
    };

    const handleClickOpen = () => {
        // setOpen(true);
    };

    const handleClose = () => {
        setIsopen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!productInfo.type_name || !productInfo.image) {
                toast.error("Please fill in all fields", { autoClose: 500, theme: 'colored' });
            } else {
                const requestData = {
                    type_name: productInfo.type_name,
                    image: productInfo.image,
                };

                if (Object.keys(getTypeInfo).length !== 0) {
                    requestData.id = getTypeInfo.id;
                    requestData.update = true;
                } else {
                    requestData.add = true;
                }

                const { data } = await axios.post(`${Consts.BACKEND_URL}products/product_type.php`, requestData, {
                    headers: {
                        'Authorization': authToken,
                    },
                });

                // setOpen(false);

                if (data?.success === true) {
                    toast.success("Product added successfully", { autoClose: 500, theme: 'colored' });
                    setProductInfo({
                        type_name: "",
                        image: "",
                    });
                } else {
                    toast.error("Something went wrong", { autoClose: 500, theme: 'colored' });
                }
            }
        } catch (error) {
            toast.error(error.response ? error.response.data.error : 'Network Error', { autoClose: 500, theme: 'colored' });
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: "20px 0" }} >
                <Typography variant='h6' textAlign='center' color="#1976d2" fontWeight="bold">Add new product</Typography>
                <Button variant='contained' endIcon={<MdProductionQuantityLimits />} onClick={handleClickOpen}>Add</Button>
            </Box>
            <Divider sx={{ mb: 5 }} />
            <Dialog
                open={true}
                onClose={handleClose}
                keepMounted
                TransitionComponent={Transition}>
                <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', color: "#1976d2" }}>Add new product Type</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <TextField label="Type Name" name='type_name' value={productInfo.type_name} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField label="Image URL" name='image' value={productInfo.image} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                            </Grid>
                            <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                <Button fullWidth variant='contained' type='reset' color='error' onClick={handleClose} endIcon={<MdOutlineCancel />}>Cancel</Button>
                                <Button type="submit" fullWidth variant="contained" endIcon={<MdProductionQuantityLimits />}>Submit</Button>
                            </DialogActions>
                        </form>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddProductType;
