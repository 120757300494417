import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import { CgProfile, CgUser } from "react-icons/cg";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";

export default function UserDrawer({ style }) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const [urlItems , setUrlItems] = React.useState([
    {
        name :"Admin",
        icon :   <CgUser className="nav-icon" />,
        path : "/admin/home"
    },
    {
        name :"Notification",
        icon :   <CgUser className="nav-icon" />,
        path :"/notification"
    }
  ])

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem key={"Profile"} disablePadding>
          <NavLink to="/update" style={{ width: "100%" }}>
            <ListItemButton>
              <ListItemIcon>
                <CgProfile style={{ fontSize: 40 }} />
              </ListItemIcon>
              <ListItemText primary={"profile"} />
            </ListItemButton>
          </NavLink>
        </ListItem>
      </List>
      <Divider />
      <List>
        {urlItems.map((data, index) => (
          <ListItem key={index} disablePadding>
             <NavLink to={data?.path} style={{ width: "100%" }}>
            <ListItemButton>
              <ListItemIcon>
                {data?.icon}
              </ListItemIcon>
              <ListItemText primary={data?.name} />
            </ListItemButton>
            </NavLink>
          </ListItem>

        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <CgProfile /> : <CgProfile />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <CgProfile style={style} onClick={toggleDrawer("left", true)} />
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
