import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/Home/HomePage';
import Login from './Auth/Login/Login';
import Register from './Auth/Register/Register';
import Cart from './Pages/Cart/Cart';
import ProductDetail from './Pages/Detail/ProductDetail';
import SingleCategory from './SingleCategory/SingleCategory';
import MobileNavigation from './Navigation/MobileNavigation';
import DesktopNavigation from './Navigation/DesktopNavigation';
import Wishlist from './Pages/WhisList/Wishlist';
import PaymentSuccess from './Pages/Payment/PaymentSuccess';
import { Flip, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckoutForm from './Components/Checkout/CheckoutForm';
import UpdateDetails from './Pages/Update_User/UpdateDetails';
import ForgotPasswordForm from './Auth/ForgotPassword/ForgotPasswordForm';
import AddNewPassword from './Auth/ForgotPassword/AddNewPassword';
import AdminLogin from './Admin/Auth/Login/AdminLogin';
import AdminRegister from './Admin/Auth/Register/AdminRegister';
import AdminHomePage from './Admin/Pages/AdminHomePage';
import SingleUserPage from './Admin/Pages/SingleUserPage';
import SingleProduct from './Admin/Pages/SingleProduct';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Consts } from './Constants/Consts';
import Products from './AllProducts/Products';
import UserDrawer from './Components/UserDrawer/UserDrawer';
import NotificationSender from './Components/Notifications/NotificationSender';





function App() {

  const [userType,setUserType] = useState(null);
  let authToken = localStorage.getItem("Authorization");


  const checkToken = async () => {
    try {
        const { data } = await axios.get(`${Consts.BACKEND_URL}/config/checkToken.php`, {
            headers: {
                'Authorization': authToken
            }
        });
        if (!data?.success) {
            localStorage.removeItem("Authorization");
        } else {
            setUserType(data?.role_id);
            localStorage.setItem('Authorization', data?.authToken);
        }

    } catch (error) {
        console.log(error);
    }
};


  useEffect(() => {
    checkToken();
  },[userType , authToken]);





  return (
    <>
      <ToastContainer toastClassName='toastContainerBox' transition={Flip} position='top-center' />
      <Router>
        <DesktopNavigation setUserType={setUserType}  userType={userType} />
        <div className='margin'>
          <Routes>
            {/*User Routes  */}
            <Route  path='/' index element={<HomePage />} />
            <Route path="/login" element={< Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='product/:type' element={<Products />} />
            <Route path='/Detail/type/:cat/:productId/:productName' element={<ProductDetail />} />
            <Route path='product/type/:type' element={<SingleCategory />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/wishlist' element={<Wishlist />} />
            <Route path='/checkout' element={<CheckoutForm />} />
            <Route path='/update' element={<UpdateDetails />} />
            <Route path='/paymentsuccess' element={<PaymentSuccess />} />
            <Route path='/forgotpassword' element={<ForgotPasswordForm />} />
            <Route path='/user/reset/:id/:token' element={<AddNewPassword />} />

            {/* Admin Routes */}
            <Route path="/admin/login" element={< AdminLogin  />} />
            <Route path='/admin/register' element={<AdminRegister />} />

            {
              (userType ===  Consts.USER_TYPE.ADMIN  || userType === Consts.USER_TYPE.VENDOR) &&
            <>
            <Route path='/admin/home' element={<AdminHomePage checkToken={checkToken} />} />
            <Route path='/admin/home/user/:id' element={<SingleUserPage />} />
            <Route path='/admin/home/product/:type/:id' element={<SingleProduct />} />


            <Route path='/notification' element={<NotificationSender />} />



            <Route
                    path="*"
                    element={<HomePage />}
                />
            </>
}
          </Routes>
        </div>

        <MobileNavigation setUserType={setUserType} />
      </Router >


    </>
  );
}
export default App;
