import "./singlecategory.css";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Container } from "@mui/system";
import { Box, Button, MenuItem, FormControl, Select, InputLabel } from "@mui/material";
import Loading from "../Components/loading/Loading";
import { BiFilterAlt } from "react-icons/bi";
import ProductCard from "../Components/Card/Product Card/ProductCard";
import CopyRight from "../Components/CopyRight/CopyRight";
import { Consts } from "../Constants/Consts";
import { toast } from "react-toastify";

const SingleCategory = () => {
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOption, setFilterOption] = useState("");
  const [productFilter, setProductFilter] = useState([]);
  const [open, setOpen] = useState(false);
  const { type } = useParams();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    getCategoryProduct();
    window.scroll(0, 0);
  }, []);

  const getCategoryProduct = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${Consts.BACKEND_URL}products/get_all_product.php?type=${type}`
      );
      setIsLoading(false);

      if (data?.success) {
        setProductData(data?.products);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (type != null) {
      getProductCategory();
    }
  }, []);

  const getProductCategory = async () => {
    try {
      const { data } = await axios.get(
        `${Consts.BACKEND_URL}products/getProductCategory.php?product_type=${type}`
      );
      setProductFilter(data?.productCategories);
    } catch (error) {
      toast.error(error.response.data, { autoClose: 500, theme: "colored" });
    }
  };

  const handleChange = (e) => {
    setFilterOption(e.target.value);
  };
  // pricelowtohigh
  // pricehightolow
  // highrated
  // lowrated

  useEffect(() => {
    if (filterOption != "" && filterOption != undefined && filterOption != null) {
      getData();
    }
  }, [filterOption]);
  const getData = async () => {
    setIsLoading(true);
    const { data } = await axios.get(
      `${Consts.BACKEND_URL}products/get_all_product.php?type=${type}&category=${filterOption}`
    );
    setProductData(data?.products);
    setIsLoading(false);
  };

  const loading = isLoading ? (
    <Container
      maxWidth="xl"
      style={{
        marginTop: 10,
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        paddingLeft: 10,
        paddingBottom: 20,
      }}
    >
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
    </Container>
  ) : (
    ""
  );
  return (
    <>
      <Container
        maxWidth="xl"
        style={{
          marginTop: 90,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ minWidth: 140 }}>
          <FormControl sx={{ width: 140 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
                width: "80vw",
              }}
            >
              <Button endIcon={<BiFilterAlt />}>Filters</Button>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-controlled-open-select-label">Filter</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          value={filterOption}
          sx={{ width: 200 }}
          onChange={(e) => handleChange(e)}
          onClose={handleClose}
          onOpen={handleOpen}
          label="Filter"
        >
                {productFilter &&
                  productFilter.map((prod, index) => (
                    <MenuItem key={index} value={prod?.category_name}>
                      {prod?.category_name}
                    </MenuItem>
                  ))}
              </Select>
              </FormControl>
            </Box>
          </FormControl>
        </Box>
        {loading}
        <Container
          maxWidth="xl"
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            paddingBottom: 20,
            paddingLeft :0,
            paddingRight : 0, 
            marginBottom: 30,
            width: "100%",
          }}
        >
          {productData &&
            productData?.map((prod) => (
              <div className="col-lg-3 col-md-4 col-6 px-lg-4 px-0">

             
              <Link
                to={`/Detail/type/${type}/${prod.id}/${prod.name}`}
                key={prod.id}
              >
                <ProductCard prod={prod} />
              </Link>
              </div>
            ))}
        </Container>
      </Container>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};

export default SingleCategory;

//
