import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useContext } from 'react'
import { ContextFunction } from '../../Context/Context'
import CategoryCard from '../../Components/Category_Card/CategoryCard';
import getProductType from '../../Helpers/TypeBanner';
import Carousel from '../../Components/Carousel/Carousel'
import SearchBar from '../../Components/SearchBar/SearchBar'
import CopyRight from '../../Components/CopyRight/CopyRight'
import { Consts } from '../../Constants/Consts'
const HomePage = () => {
    const { setCart } = useContext(ContextFunction);
    const [porductType , setProductType] = useState([]);
    let authToken = localStorage.getItem('Authorization');
    useEffect(() => {
        showCategory();
        getCart();
        window.scroll(0, 0)
    }, []);
    const getCart = async () => {
        if (authToken !== null) {
            const { data } = await axios.get(`${Consts.BACKEND_URL}carts/get_cart_items.php`,
                {
                    headers: {
                        'Authorization': authToken
                    }
                })
                if(data?.success){
                    setCart(data?.cartItems || []);
                }

        }

    }

const showCategory=()=>{
    getProductType().then((res)=>{
        console.log(res)
setProductType(res?.productTypes);
    }).catch((e)=>{console.log(e)})
}

    return (
        <>
            <Container maxWidth='xl' style={{ display: 'flex', justifyContent: "center", padding: 0, flexDirection: "column", marginBottom: 70 }}>
                <Box padding={1}>
                    <Carousel />
                </Box>
                <Container style={{ marginTop: 90, display: "flex", justifyContent: 'center' }}>
                    <SearchBar />
                </Container>
                <Typography variant='h3' sx={{ textAlign: 'center', marginTop: 10, color: '#1976d2', fontWeight: 'bold' }}>Categories</Typography>
                <Container maxWidth='xl' style={{ marginTop: 90, display: "flex", justifyContent: 'center', flexGrow: 1, flexWrap: 'wrap', gap: 20, }}>
                    {
                       porductType && porductType?.map((data,index) => (
                            <CategoryCard data={data} key={index} />
                        ))
                    }
                </Container>
            </Container >
            <CopyRight sx={{ mt: 8, mb: 10 }} />
        </ >
    )
}

export default HomePage